<template>
  <el-row class="mt28" :gutter="4">
    <slot></slot>
    <div class="el-col-style">
      <el-input
        class="el-input-style"
        style="width: 240px"
        v-model.trim="queryObj.InputText"
        size="small"
        :placeholder="`请输入${placeholder}`"
        @keyup.enter.native="searchList"
      ></el-input>
      <el-select
        class="el-select-style"
        style="width: 100px"
        size="small"
        placeholder="设备名称"
        v-model="queryObj.deviceName"
        @change="searchTypeChange"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button
        style="margin-left: 4px;height:32px;"
        size="small"
        type="primary"
        @click="searchList"
        >查询</el-button
      >
      <el-button
        v-if="portFlag"
        style="margin-left: 4px"
        size="small"
        type="primary"
        @click="portClick"
        >导出</el-button
      >
    </div>
  </el-row>
</template>

<script>
import { stringify } from 'qs';

export default {
  props: {
    options: {
      type: Array,
      default: [],
    },
    portFlag: { //导出
        type:Boolean,
        default:false
    },
    seleShift:{
      type:stringify,
      default:""
    },
    inputText:{
      type:stringify,
      default:""
    }
  },
  data() {
    return {
      queryObj: {
        deviceName: "",
        InputText: "",
      },
      placeholder: "",
    };
  },
  watch: {},
  mounted() {
    this.queryObj.deviceName = this.options[0].value
    this.placeholder = this.options[0].label;
    console.log(this.seleShift,'11111111')
    if(this.seleShift) {
      this.queryObj.deviceName = this.seleShift
      let dataName = this.options.find(item=>item.value == this.seleShift)
      this.placeholder = dataName.label;
      this.$emit("selectSearchChange", this.queryObj);
    }
    console.log(this.inputText,'111111111')
    if(this.inputText) {
      this.queryObj.InputText = this.inputText
      this.$emit("selectSearchChange", this.queryObj);
    }
    this.$emit("selectSearchChange", this.queryObj);
  },
  methods: {
    searchTypeChange(val) {
      let dataName = this.options.find(item=>item.value == val)
      this.placeholder = dataName.label;
      this.$set(this.queryObj,'InputText','')
      this.$emit("selectSearchChange", this.queryObj);
    },
    searchList() {
      this.$emit("searchList", this.queryObj);
    },
    portClick() {
      this.$emit("searchList", this.queryObj);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-col-style {
  display: flex;
  position: relative;
  margin: -10px 0 0 -6px;
}
.el-select-style {
  position: absolute;
  left: 2px;
  top: 0;
  border: none;
  border-right: 1px solid #c0c4cc;
  margin-top: 5px;
  margin-right: 2px;
}

::v-deep .el-select-style {
  .el-input__inner {
    border: 0;
    height: 23px;
    line-height: 23px;
  }
  .el-input--small .el-input__icon {
    line-height: 23px;
  }
}
::v-deep .el-input-style {
  .el-input__inner {
    padding-left: 110px;
  }
}
.mt28 {
  padding: 10px 4px 10px 10px;//如果后边有导出按钮的情况上10px 右10px 下10px 左10px
}
</style>
